import {
    AfterViewInit,
    Component,
    computed,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseHorizontalNavigationComponent,
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { IClinic, ISystemMessage, IUserBadges } from 'app/core/models/user/user';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { KiwidPermissionsService } from 'app/core/permissions/service/kiwid-permissions.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { CommunicationService } from 'app/core/services/communication/communication.service';
import { ScrollBarService } from 'app/core/services/scrollbar/scroll-bar.service';
import { BaseComponent } from 'app/layout/common/base/base.component';

import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';




import { UserComponent } from 'app/layout/common/user/user.component';
import { KNotificationComponent } from '@kiwid-app/kiwid-ui-lib-angular';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';

@Component({
    selector: 'enterprise-layout',
    templateUrl: './enterprise.component.html',
    styleUrls: ['./enterprise.component.scss'],
    imports: [FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, LanguagesComponent, MessagesComponent, NotificationsComponent, UserComponent, FuseHorizontalNavigationComponent, RouterOutlet, KNotificationComponent]
})
export class EnterpriseLayoutComponent extends BaseComponent implements AfterViewInit, OnInit, OnDestroy {
    // Inject
    private _router = inject(Router);
    private _navigationService = inject(NavigationService);
    private _fuseMediaWatcherService = inject(FuseMediaWatcherService);
    private _fuseNavigationService = inject(FuseNavigationService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly kiwidPermissionsService = inject(KiwidPermissionsService);
    private readonly communicationService = inject(CommunicationService);
    private readonly scrollBarService = inject(ScrollBarService);
    private readonly activatedRoute = inject(ActivatedRoute);


    @ViewChild(FuseScrollbarDirective, {
        read: FuseScrollbarDirective,
        static: false,
    }) fuseScrollbar: FuseScrollbarDirective | null;
    protected readonly isScreenSmall = signal(false);
    protected readonly userBadges = signal<IUserBadges>({});
    protected readonly systemMessages = signal<ISystemMessage[]>([]);
    protected readonly isOnboarding = signal(false);
    protected readonly navigation = signal<Navigation>({
        compact: [],
        default: [],
        futuristic: [],
        horizontal: [],
    });
    private retryConnectSSECount = 0;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private eventSourceBadge: EventSource | null = null;
    @ViewChild('refDivScroll') refDivScroll: ElementRef<HTMLDivElement>;

    protected enableNotification = computed(() => this.systemMessages().length > 0 && !!this.authenticationService.clinicId());
    protected navigationDefault = computed(() => this.navigation().default);
    protected clinicSelected = computed<IClinic | null>(() => this.authenticationService.clinicSelected());
    protected isShowSupport = computed<boolean>(() => !!this.clinicSelected() && !this.authenticationService.userIsKiwid && this.isProduction);

    /**
     * Constructor
     */
    constructor() {
        super();
        this.registerOnTranslate('NAV', portuguese, english, spanish, false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerGetUserBadges();

        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            if (this.kiwidPermissionsService.initialize.getValue()) {
                this.navigation.set(this.checkNavigationPermissionsAndTranslate({ ...navigation }));
                return;
            }
            this.kiwidPermissionsService.initialize.subscribe((value) => {
                if (value) {
                    this.navigation.set(this.checkNavigationPermissionsAndTranslate({ ...navigation }));
                }
            });
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall.set(!matchingAliases.includes('md'));
        });
    }

    ngAfterViewInit(): void {
        this._router.events
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.isOnboarding.set(this._router.url.search('/public/onboarding/') > -1);

                if (this._router.getCurrentNavigation()?.extras?.queryParamsHandling === 'merge') {
                    const enableScrollToTop =
                        this.activatedRoute.snapshot.queryParams?.page === 1 || this.activatedRoute.snapshot.queryParams?.offset === 0;

                    setTimeout(() => {
                        if (enableScrollToTop) {
                            this.refDivScroll?.nativeElement?.scrollTo({
                                top: 0,
                            });
                        }
                    }, 0);
                    return;
                }

                setTimeout(() => {
                    this.refDivScroll?.nativeElement?.scrollTo({
                        top: 0,
                    });
                }, 0);
            });

        this.scrollBarService.refDivScroll = this.refDivScroll.nativeElement;
    }

    private checkNavigationPermissionsAndTranslate(navigation: Navigation): Navigation {
        const checkPermissionAndTranslateChildren = (children: FuseNavigationItem[]): FuseNavigationItem[] => {
            for (const child of children) {
                child.title = this.translateKey(child.title);

                if (!!child.permissions) {
                    child.hidden = (item) => {
                        return !this.kiwidPermissionsService.hasPermission(item.permissions);
                    };
                }

                if (child.children?.length > 0) {
                    child.children = checkPermissionAndTranslateChildren(child.children ?? []);
                }
            }

            return children;
        };


        return {
            compact: checkPermissionAndTranslateChildren(navigation.compact),
            default: checkPermissionAndTranslateChildren(navigation.default),
            futuristic: checkPermissionAndTranslateChildren(navigation.futuristic),
            horizontal: checkPermissionAndTranslateChildren(navigation.horizontal),
        };
    }

    /**
     * On destroy
     */
    override ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    private registerGetUserBadges(): void {
        if (this.retryConnectSSECount >= 10) {
            return;
        }
        if (this.eventSourceBadge) {
            this.eventSourceBadge.close();
        }
        const [eventSourceBadgeObservable, eventSource] = this.communicationService.getEventSourceBadges({
            userId: this.authenticationService.user().id,
            clinicId: this.authenticationService.clinicId(),
        });
        this.eventSourceBadge = eventSource;
        this.subscriptions.add(
            eventSourceBadgeObservable.subscribe({
                next: ({ data }) => {
                    this.userBadges.set({ ...this.userBadges(), ...data });
                },
                error: (error) => {
                    this.eventSourceBadge.close();
                    this.eventSourceBadge = null;
                    if (environment.type !== 'local') {
                        setTimeout(() => {
                            this.retryConnectSSECount++;
                            this.registerGetUserBadges();
                        }, 10000);
                    }
                },
            }),
        );
    }

    get isProduction(): boolean {
        return environment.type === 'production';
    }

    clickCloseSystemMessage(index: number): void {
        this.systemMessages.update((items) => items.filter((_, idx) => idx !== index));
    }
}
