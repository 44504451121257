import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { NgClass } from '@angular/common';
import { Component, ComponentRef, computed, EventEmitter, OnDestroy, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISidebarConfig } from './sidebar-config.interface';
import { SidebarService } from './sidebar.service';
import { BtnComponent, IDialogButton, TitleModelSidebarComponent } from '@kiwid-app/kiwid-ui-lib-angular';

@Component({
    selector: 'k-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    imports: [PortalModule, TitleModelSidebarComponent, BtnComponent, TitleModelSidebarComponent, BtnComponent, NgClass]
})
export class SidebarComponent implements OnDestroy, OnInit {
    readonly buttonEventClick: EventEmitter<IDialogButton> = new EventEmitter<IDialogButton>();
    protected readonly config = signal<ISidebarConfig>({
        title: 'Kiwid Sidebar',
        buttons: [
            {
                id: 2,
                text: 'Cancelar',
                type: 'secondary',
            },
            {
                id: 1,
                text: 'Confirmar',
                type: 'primary',
            },
        ],
        component: null,
        padding: true,
        typeHeader: 'primary',
    });
    private readonly subscriptions = new Subscription();

    protected getPaddingEnabled = computed(() => this.config().padding ?? true);

    constructor(private readonly sidebarService: SidebarService) {
        this.registerConfig();
    }

    get component(): ComponentPortal<any> | null {
        return this.config().component;
    }

    set component(value) {
        this.config.update((config) => ({ ...config, component: value }));
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    closeSidebar(): void {
        this.sidebarService.close();
    }

    findButton(buttonId: string | number): IDialogButton | null {
        return this.config().buttons?.find((findButton) => findButton.id === buttonId) ?? null;
    }

    clickButton(button: IDialogButton): void {
        this.buttonEventClick.emit(this.findButton(button.id) as IDialogButton);
        this.sidebarService.clickButton(this.findButton(button.id) as IDialogButton);
    }

    componentRef<TComponent>(ref: ComponentRef<TComponent>): void {
        this.sidebarService.registerComponentRef<TComponent>(ref);
    }

    scrollEvent(event: Event): void {
        this.sidebarService.scrollEvent.next(event);
    }

    private registerConfig(): void {
        this.subscriptions.add(
            this.sidebarService.configObservable.subscribe((config) => {
                this.config.set(config);
            }),
        );
    }
}
