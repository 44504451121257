import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DEFAULT_PAGINATION } from 'app/core/const/pagination.const';
import { ErrorHelper } from 'app/core/helpers/error.helper';
import { TimeTranslateHelper } from 'app/core/helpers/time-translate.helper';
import { isLastPage } from 'app/core/helpers/util';
import { IChatBadge, IGetAllChatBadge } from 'app/core/models/chat/chat';
import { ChatService } from 'app/core/services/chat/chat.service';
import { SidebarService } from 'app/layout/components/sidebar/sidebar.service';
import { BtnComponent, EmptyComponent, ToastrService } from '@kiwid-app/kiwid-ui-lib-angular';
import { first } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgTemplateOutlet } from '@angular/common';
import { UrlHelper } from 'app/core/helpers/url.helper';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { HashHelper } from 'app/core/helpers/hash.helper';

@Component({
    selector: 'app-notification-chat',
    templateUrl: './notification-chat.component.html',
    styleUrls: ['./notification-chat.component.scss'],
    imports: [MatBadgeModule, RouterModule, TranslateModule, EmptyComponent, BtnComponent, NgxSkeletonLoaderModule, NgTemplateOutlet, MatProgressSpinner]
})
export class NotificationChatComponent extends BaseComponent implements OnInit {
    readonly sidebarService = inject(SidebarService);
    private readonly timeTranslateHelper = inject(TimeTranslateHelper);
    private readonly chatService = inject(ChatService);
    private readonly toastrService = inject(ToastrService);
    private readonly urlHelper = inject(UrlHelper);

    protected readonly isLoading = signal(true);
    protected readonly isLoadingPage = signal(true);
    protected readonly isLoadingClearBadge = signal(false);
    protected readonly isLastPage = signal(false);
    private readonly chatBadges= signal<IChatBadge[]>([]);
    protected readonly renderChatBadges = computed(() => {
        return this.chatBadges().map((chatBadge) => ({
            ...chatBadge,
            order: {
                number: chatBadge.order?.number,
                laboratory: {
                    name: this.translateKey('LABORATORY', { labName: chatBadge.order?.location?.name ?? '-' }),
                    logo: chatBadge.order?.laboratory?.logo?.url
                },
            },
            routerLink: this.urlHelper.goToOrderDetailChat(chatBadge.order?.id),
            queryParams: { t: `${Date.now()}_${HashHelper.uuidv4()}` },
            quantity: chatBadge?.quantity,
            date: this.timeTranslateHelper.getTimeTranslate(chatBadge.updatedAt)
        }))
    })
    private paramPagination = signal<IGetAllChatBadge>({
        offset: 0,
        limit: DEFAULT_PAGINATION,
    });

    constructor() {
        super();
        this.registerOnTranslate('TOOLBAR_NOTIFICATION_CHAT', portuguese, english, spanish, false);
        this.getAllChatBadge();
        this.subscriptions.add(
            this.sidebarService.scrollEvent.subscribe((event) => {
                this.scrollEvent(event);
            }),
        );
    }

    ngOnInit(): void {}

    scrollEvent(event: Event): void {
        const element = event.target as Element;
        if (element.scrollHeight - element.clientHeight <= element.scrollTop + 10) {
            if (!this.isLoading() && !this.isLoadingPage() && !this.isLastPage()) {
                this.paramPagination.update((param) => ({
                    ...param,
                    offset: this.chatBadges().length
                }));
                this.getAllChatBadge();
            }
        }
    }

    private getAllChatBadge(): void {
        this.isLoadingPage.set(true);
        this.subscriptions.add(
            this.chatService
                .getAllChatBadges(this.paramPagination())
                .pipe(first())
                .subscribe({
                    next: ({ data, meta }) => {
                        if (meta.pagination.offset === 0) {
                            this.chatBadges.set([]);
                        }

                        this.chatBadges.update((items) => ([
                            ...items,
                            ...data,
                        ]));
                        this.isLastPage.set(isLastPage(meta));
                        this.isLoading.set(false);
                        this.isLoadingPage.set(false);
                    },
                    error: (error) => {
                        ErrorHelper.setToastrError(this.toastrService, error);
                        this.isLoading.set(false);
                        this.isLoadingPage.set(false);
                    },
                })
        );
    }

    protected clearBadges(): void {
        this.isLoadingClearBadge.set(true);

        this.subscriptions.add(
            this.chatService
                .clearChatBadges()
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.chatBadges.set([]);
                        this.isLoadingClearBadge.set(false);
                        this.toastrService.show({ type: 'success', message: this.translateKey('TOASTR.CLEAR') });
                        this.sidebarService.close();
                    },
                    error: (error) => {
                        ErrorHelper.setToastrError(this.toastrService, error);
                        this.isLoadingClearBadge.set(false);
                    }
                })
        );
    }
}
