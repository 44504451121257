import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EAuthenticationStorage } from 'app/core/models/authentication/authentication';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet]
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: { id: string; label: string }[];
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        // private _translocoService: TranslocoService,
        private readonly _translateService: TranslateService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the country iso codes for languages for flags
        if (environment.type === 'local') {
            this.availableLangs = [
                {
                    id: 'pt-br',
                    label: 'Português',
                },
                {
                    id: 'es',
                    label: 'Español',
                },
                {
                    id: 'en',
                    label: 'English',
                },
            ];
        } else {
            this.availableLangs = [
                {
                    id: 'pt-br',
                    label: 'Português',
                },
                {
                    id: 'es',
                    label: 'Español',
                },
            ];
        }

        this.activeLang = this._translateService.currentLang.toLowerCase();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        // Set the active lang
        lang = lang.toLowerCase() === 'pt-br' ? 'pt' : lang;
        // this._translocoService.setActiveLang(lang);
        this._translateService.use(lang);
        this.activeLang = lang;
        localStorage.setItem(EAuthenticationStorage.LANG, lang)
        window.location = window.location;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // // Get the flat navigation data
        // const navigation = navComponent.navigation;
        //
        // // Get the Project dashboard item and update its title
        // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        // if ( projectDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Project').pipe(take(1))
        //         .subscribe((translation) =>
        //         {
        //             // Set the title
        //             projectDashboardItem.title = translation;
        //
        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
        //
        // // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        // if ( analyticsDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Analytics').pipe(take(1))
        //         .subscribe((translation) =>
        //         {
        //             // Set the title
        //             analyticsDashboardItem.title = translation;
        //
        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }
}
