/* Variables */
:root {
    --fuse-drawer-width: 420px;
}

fuse-drawer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: var(--fuse-drawer-width);
    min-width: var(--fuse-drawer-width);
    max-width: var(--fuse-drawer-width);
    z-index: 300;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .35);
    @apply bg-card;

    /* Animations */
    &.fuse-drawer-animations-enabled {
        transition-duration: 400ms;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-property: visibility, margin-left, margin-right, transform, width, max-width, min-width;

        .fuse-drawer-content {
            transition-duration: 400ms;
            transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
            transition-property: width, max-width, min-width;
        }
    }

    /* Over mode */
    &.fuse-drawer-mode-over {
        position: absolute;
        top: 0;
        bottom: 0;

        /* Fixed mode */
        &.fuse-drawer-fixed {
            position: fixed;
        }
    }

    /* Left position */
    &.fuse-drawer-position-left {

        /* Side mode */
        &.fuse-drawer-mode-side {
            margin-left: calc(var(--fuse-drawer-width) * -1);

            &.fuse-drawer-opened {
                margin-left: 0;
            }
        }

        /* Over mode */
        &.fuse-drawer-mode-over {
            left: 0;
            transform: translate3d(-100%, 0, 0);

            &.fuse-drawer-opened {
                transform: translate3d(0, 0, 0);
            }
        }

        /* Content */
        .fuse-drawer-content {
            left: 0;
        }
    }

    /* Right position */
    &.fuse-drawer-position-right {

        /* Side mode */
        &.fuse-drawer-mode-side {
            margin-right: calc(var(--fuse-drawer-width) * -1);

            &.fuse-drawer-opened {
                margin-right: 0;
            }
        }

        /* Over mode */
        &.fuse-drawer-mode-over {
            right: 0;
            transform: translate3d(100%, 0, 0);

            &.fuse-drawer-opened {
                transform: translate3d(0, 0, 0);
            }
        }

        /* Content */
        .fuse-drawer-content {
            right: 0;
        }
    }

    /* Content */
    .fuse-drawer-content {
        position: absolute;
        display: flex;
        flex: 1 1 auto;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @apply bg-card;
    }
}

/* Overlay */
.fuse-drawer-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 299;
    opacity: 1;
    background-color: rgba(18, 45, 66, 0.8) !important;
    -webkit-backdrop-filter: blur(1px) !important;
    backdrop-filter: blur(1px) !important;

    /* Fixed mode */
    &.fuse-drawer-overlay-fixed {
        position: fixed;
    }

    /* Transparent overlay */
    &.fuse-drawer-overlay-transparent {
        background-color: transparent;
    }
}
